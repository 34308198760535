<template>
  <BContainer>
    <GraphicNewTcket />
    <DetailPerformance />
  </BContainer>
</template>

<script>
import { BContainer } from 'bootstrap-vue'
import 'vue-css-donut-chart/dist/vcdonut.css'
import DetailPerformance from './DetailPerformance.vue'
import GraphicNewTcket from './GraphicNewTicket.vue'

export default {
  components: {
    BContainer,
    DetailPerformance,
    GraphicNewTcket,
  },
  data() {
    return {
      dataPerformanceObject: {},
      dataPerformanceArray: [],
      isLoading: false,
    }
  },
  mounted() {
  },
}
</script>
