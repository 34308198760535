<template>
  <BCard>
    <BRow class="justify-between items-center">
      <BCol md="6">
        <strong class="text-black font-bold text-2xl">Grafik Tiket Baru</strong>
      </BCol>
      <BCol
        class="text-center flex"
        md="auto"
      >
        <BFormSelect
          v-model="expedition"
          :options="expeditionList"
          value-field="shipping_name"
          text-field="shipping_name"
          class="mr-1"
          style="height: 32px"
        >
          <template #first>
            <b-form-select-option
              :value="expeditionList.map(item => item.shipping_name).join(',')"
            >
              Semua
            </b-form-select-option>
          </template>
        </BFormSelect>
        <MonthlyPicker
          v-model="filter.month"
          date-format="MMM YYYY"
          place-holder="Pilih Bulan"
          :month-labels="monthlabel"
          :max="maxDatePicker"
        />
      </BCol>
    </BRow>
    <BOverlay
      :show="isLoading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <Apexcharts
        ref="myChart"
        height="300"
        type="area"
        :options="chartOptions"
        :series="seriesChart"
      />
    </BOverlay>
  </BCard>
</template>

<script>
import {
  BCard, BRow, BCol, BFormSelect,
} from 'bootstrap-vue'
import MonthlyPicker from 'vue-monthly-picker'
import { mapState } from 'vuex'
import moment from 'moment'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import Apexcharts from 'vue-apexcharts'
import {
  END_DATE_OF_MONTH,
  LABELMONTH,
  START_DATE_OF_MONTH,
} from '@/libs/filterDate'
import { chartOptions } from './config'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormSelect,
    MonthlyPicker,
    Apexcharts,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        month: this.$moment(),
      },
      seriesChart: [],
      chartOptions,
      expedition: '',
      monthlabel: LABELMONTH,
    }
  },
  computed: {
    ...mapState('filter', ['expeditionList']),
    maxDatePicker() {
      return this.$moment().endOf('month')
    },
  },
  watch: {
    'filter.month': {
      handler() {
        this.getDataTotalTicket()
      },
    },
    expedition: {
      handler() {
        this.getDataTotalTicket()
      },
    },
  },
  async mounted() {
    if (this.expeditionList.length === 0) {
      this.$store.dispatch('filter/getUpdateExpedition')
    }
    this.expedition = this.expeditionList.map(item => item.shipping_name).join(',')
    this.getDataTotalTicket()
  },
  methods: {
    async getDataTotalTicket() {
      this.isLoading = true
      const params = {
        start_date: START_DATE_OF_MONTH(this.filter.month),
        end_date: END_DATE_OF_MONTH(this.filter.month),
        expedition: this.expedition,
      }
      await this.$http_komship
        .get('/v1/admin/dashboard/total-ticket', { params })
        .then(res => {
          const { date } = res.data
          const keysExpeditions = Object.keys(date[0])
          const expeditions = keysExpeditions.filter(key => key !== 'date')
          if (expeditions.length !== 0) {
            this.seriesChart = expeditions.map(expedition => ({
              name: expedition,
              data: date.map(item => item[expedition]),
            }))
            this.chartOptions = {
              ...this.chartOptions,
              xaxis: {
                ...this.chartOptions.xaxis,
                categories: date.map(item => moment(item.date).format('DD')),
              },
            }
          }
          this.isLoading = false
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                text: error,
                variant: 'danger',
              },
            },
            2000,
          )
          this.isLoading = false
        })
    },
  },
}
</script>
