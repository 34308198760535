const colorDefaultChart = ['#76C5FF', '#FFDC76', '#FF7676', '#800080', '#FF0000', '#FFA500']

export const chartOptions = {
  colors: colorDefaultChart,
  chart: {
    type: 'area',
    stacked: false,
    height: 350,
    width: '100%',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
  },
  stroke: {
    curve: 'smooth',
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.75,
      opacityTo: 0.05,
      stops: [20, 100, 100, 100],
    },
  },
  yaxis: {
    forceNiceScale: true,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  xaxis: {
    type: 'category',
    categories: [],
    tickAmount: 14,
  },
  noData: {
    text: 'Halo admin, saat ini sedang tidak ada data',
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center',
  },
  tooltip: {
    shared: false,
    custom({ dataPointIndex, w }) {
      const arrayData = [...w.globals.series]
      let htmlRender = ''
      arrayData.forEach((data, idx) => {
        const seriesName = w.globals.seriesNames[idx]
        const seriesValue = data[dataPointIndex]
        htmlRender += `<div>
                <li style="color: ${colorDefaultChart[idx]}">
                  <span style="color: #222222">${seriesName} : ${seriesValue} Tiket</span>
                </li>
              </div>`
      })

      return `<div class="px-1 py-75" style="box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);">${htmlRender}</div>`
    },
  },
}

export const fieldResponseSpeed = [
  {
    key: 'name',
    label: 'Nama Mitra',
    trClass: 'border-top-0',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
    },
    class: 'bg-white',
  },
  {
    key: 'image_path',
    label: 'Ekspedisi',
    trClass: 'border-top-0',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
      minWidth: '100px',
    },
    class: 'bg-white',
  },
  {
    key: 'response_speed',
    label: 'Respon',
    trClass: 'border-top-0',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
    },
    class: 'bg-white',
  },
]

export const fieldHandlingSpeed = [
  {
    key: 'name',
    label: 'Nama Mitra',
    trClass: 'border-top-0',
    class: 'bg-white',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
    },
  },
  {
    key: 'image_path',
    label: 'Ekspedisi',
    trClass: 'border-top-0',
    class: 'bg-white',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
      minWidth: '100px',
    },
  },
  {
    key: 'handling_speed',
    label: 'Penanganan',
    trClass: 'border-top-0',
    class: 'bg-white',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
    },
  },
]

export const fieldHandlingSuccess = [
  {
    key: 'name',
    label: 'Nama Mitra',
    trClass: 'border-top-0',
    class: 'bg-white',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
    },
  },
  {
    key: 'image_path',
    label: 'Ekspedisi',
    trClass: 'border-top-0',
    class: 'bg-white',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
      minWidth: '100px',
    },
  },
  {
    key: 'shipping',
    label: 'Pengiriman',
    trClass: 'border-top-0',
    class: 'bg-white',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
      minWidth: '100px',
    },
  },
  {
    key: 'handling_success',
    label: 'Rank',
    trClass: 'border-top-0',
    class: 'bg-white',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
    },
  },
]

export const fieldRating = [
  {
    key: 'name',
    label: 'Nama Mitra',
    trClass: 'border-top-0',
    class: 'bg-white',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
    },
  },
  {
    key: 'image_path',
    label: 'Ekspedisi',
    trClass: 'border-top-0',
    class: 'bg-white',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
      minWidth: '100px',
    },
  },
  {
    key: 'rating',
    label: 'Rate',
    trClass: 'border-top-0',
    class: 'bg-white',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
    },
  },
]
