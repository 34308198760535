<template>
  <BRow class="mt-1">
    <BCol lg="6">
      <BCard
        style="
          border: 1px solid #e2e2e2;
          box-shadow: 4px 4px 48px rgba(0, 0, 0, 0.08);
          border-radius: 16px;
        "
      >
        <div class="text-black font-bold text-xl mb-1">
          Top Kecepatan Respon
        </div>
        <BOverlay
          :show="loadingResponseSpeed"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <BTable
            :items="itemResponseSpeed"
            :fields="fieldResponseSpeed"
            show-empty
            empty-text="Tidak ada data yang ditampilkan."
            responsive
          >
            <template #cell(image_path)="data">
              <img
                :src="data.value"
                alt="Komerce"
                style="width: 50px"
              >
            </template>
            <template #cell(response_speed)="data">
              <span>{{ handleFieldResponse(data.value) }}</span>
            </template>
          </BTable>
        </BOverlay>
      </BCard>
    </BCol>
    <BCol lg="6">
      <BCard
        style="
          border: 1px solid #e2e2e2;
          box-shadow: 4px 4px 48px rgba(0, 0, 0, 0.08);
          border-radius: 16px;
        "
      >
        <div class="text-black font-bold text-xl mb-1">
          Top Kecepatan Penanganan
        </div>
        <BOverlay
          :show="loadingHandlingSpeed"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <BTable
            :items="itemHandlingSpeed"
            :fields="fieldHandlingSpeed"
            show-empty
            empty-text="Tidak ada data yang ditampilkan."
            responsive
          >
            <template #cell(image_path)="data">
              <img
                :src="data.value"
                alt="Komerce"
                style="width: 50px"
              >
            </template>
            <template #cell(handling_speed)="data">
              <span>{{ handleFieldResponse(data.value) }}</span>
            </template>
          </BTable>
        </BOverlay>
      </BCard>
    </BCol>
    <BCol lg="6">
      <BCard
        style="
          border: 1px solid #e2e2e2;
          box-shadow: 4px 4px 48px rgba(0, 0, 0, 0.08);
          border-radius: 16px;
        "
      >
        <div class="text-black font-bold text-xl mb-1">
          Top Kesuksesan Penanganan
        </div>
        <BOverlay
          :show="loadingHandlingSuccess"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <BTable
            :items="itemHandlingSuccess"
            :fields="fieldHandlingSuccess"
            show-empty
            empty-text="Tidak ada data yang ditampilkan."
            responsive
          >
            <template #cell(image_path)="data">
              <img
                :src="data.value"
                alt="Komerce"
                style="width: 50px"
              >
            </template>
          </BTable>
        </BOverlay>
      </BCard>
    </BCol>
    <BCol lg="6">
      <BCard
        style="
          border: 1px solid #e2e2e2;
          box-shadow: 4px 4px 48px rgba(0, 0, 0, 0.08);
          border-radius: 16px;
        "
      >
        <div class="text-black font-bold text-xl mb-1">
          Top Review
        </div>
        <BOverlay
          :show="loadingRating"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <BTable
            :items="itemRating"
            :fields="fieldRating"
            show-empty
            empty-text="Tidak ada data yang ditampilkan."
            responsive
          >
            <template #cell(image_path)="data">
              <img
                :src="data.value"
                alt="Komerce"
                style="width: 50px"
              >
            </template>
            <template #cell(rating)="data">
              <div style="display: flex; align-items: center;">
                <span>{{ data.value }}</span>
                <BFormRating
                  :value="data.value"
                  readonly
                  class="rating-review"
                  variant="warning"
                />
              </div>
            </template>
          </BTable>
        </BOverlay>
      </BCard>
    </BCol>
  </BRow>
</template>

<script>
import {
  BRow, BCol, BCard, BFormRating,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormRating,
  },
  props: {
    itemResponseSpeed: {
      type: Array,
      default: () => [],
    },
    itemHandlingSpeed: {
      type: Array,
      default: () => [],
    },
    itemHandlingSuccess: {
      type: Array,
      default: () => [],
    },
    itemRating: {
      type: Array,
      default: () => [],
    },
    fieldResponseSpeed: {
      type: Array,
      default: () => [],
    },
    fieldHandlingSpeed: {
      type: Array,
      default: () => [],
    },
    fieldHandlingSuccess: {
      type: Array,
      default: () => [],
    },
    fieldRating: {
      type: Array,
      default: () => [],
    },
    loadingResponseSpeed: {
      type: Boolean,
      default: false,
    },
    loadingHandlingSpeed: {
      type: Boolean,
      default: false,
    },
    loadingHandlingSuccess: {
      type: Boolean,
      default: false,
    },
    loadingRating: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleFieldResponse(value) {
      if (value?.day === 0 && value?.hours === 0 && value?.minutes === 0) {
        return '0 menit'
      }
      if (value?.day === 0) {
        return `${value?.hours} jam ${value?.minutes} menit`
      }
      if (value?.day === 0 && value?.hours === 0) {
        return `${value?.minutes} menit`
      }
      return `${value?.day} hari ${value?.hours} jam ${value?.minutes} menit`
    },
  },
}
</script>

<style scoped>
.rating-review{
  padding: 0;
  border: none;
}
</style>
