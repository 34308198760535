<!-- eslint-disable vue/no-template-shadow -->
<template>
  <BCard>
    <div class="flex items-center justify-between">
      <strong class="text-black font-bold text-2xl">Detail Performa</strong>
      <DateRangePicker
        ref="picker"
        v-model="dateRange"
        :locale-data="locale"
        :ranges="ranges"
        :opens="'left'"
        class="performance-date-range"
      >
        <template v-slot:input="picker">
          <div class="d-flex justify-content-between align-items-center w-100">
            <div class="mr-1">
              <span
                v-if="
                  YEAR_MONTH_DAY(picker.startDate) ===
                    YEAR_MONTH_DAY(komshipDate)
                "
                style="color: #828282 !important"
              >Semua waktu</span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last7)
                "
                style="color: #828282 !important"
              >7 Hari Terakhir</span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last30)
                "
                style="color: #828282 !important"
              >30 Hari Terakhir
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) ===
                    YEAR_MONTH_DAY(firstDateOfMonth) &&
                    YEAR_MONTH_DAY(picker.endDate) === YEAR_MONTH_DAY(today)
                "
                style="color: #828282 !important"
              >Bulan ini</span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) ===
                    YEAR_MONTH_DAY(last90) &&
                    YEAR_MONTH_DAY(picker.endDate) === YEAR_MONTH_DAY(today)
                "
                style="color: #828282 !important"
              >3 Bulan Terakhir</span>
              <span
                v-else
                style="color: #828282 !important"
              >Custom Tanggal</span>
            </div>
            <div>
              <feather-icon icon="ChevronDownIcon" />
            </div>
          </div>
        </template>
      </DateRangePicker>
    </div>
    <Table
      :item-response-speed="itemResponseSpeed"
      :item-handling-speed="itemHandlingSpeed"
      :item-handling-success="itemHandlingSuccess"
      :item-rating="itemRating"

      :field-response-speed="fieldResponseSpeed"
      :field-handling-speed="fieldHandlingSpeed"
      :field-handling-success="fieldHandlingSuccess"
      :field-rating="fieldRating"

      :loading-response-speed="loadingResponseSpeed"
      :loading-handling-speed="loadingHandlingSpeed"
      :loading-rating="loadingRating"
      :loading-handling-success="loadingHandlingSuccess"
    />
  </BCard>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  firstDateOfMonth,
  komshipDate,
  last30,
  last7,
  last90,
  lastDateOfMonth,
  today,
} from '@/store/helpers'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { YEAR_MONTH_DAY, LABELDAY, LABELMONTH } from '@/libs/filterDate'
import Table from './Table.vue'
import {
  fieldResponseSpeed, fieldHandlingSpeed, fieldHandlingSuccess, fieldRating,
} from './config'

export default {
  components: {
    BCard,
    DateRangePicker,
    Table,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      picker: {
        startDate: firstDateOfMonth,
        endDate: lastDateOfMonth,
      },
      dateRange: {
        startDate: firstDateOfMonth,
        endDate: today,
      },
      firstDateOfMonth,
      lastDateOfMonth,
      komshipDate,
      today,
      last7,
      last30,
      last90,
      YEAR_MONTH_DAY,
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        'Semua Waktu': [komshipDate, today],
        '7 hari terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan ini': [firstDateOfMonth, today],
        '3 Bulan Terakhir': [last90, today],
      },

      itemResponseSpeed: [],
      itemHandlingSpeed: [],
      itemHandlingSuccess: [],
      itemRating: [],

      fieldResponseSpeed,
      fieldHandlingSpeed,
      fieldHandlingSuccess,
      fieldRating,

      loadingResponseSpeed: false,
      loadingHandlingSpeed: false,
      loadingHandlingSuccess: false,
      loadingRating: false,
    }
  },
  watch: {
    dateRange: {
      handler() {
        this.fetchResponseSpeed()
        this.fetchHandlingSpeed()
        this.fetchHandlingSuccess()
        this.fetchRating()
      },
    },
  },
  mounted() {
    this.fetchResponseSpeed()
    this.fetchHandlingSpeed()
    this.fetchHandlingSuccess()
    this.fetchRating()
  },
  methods: {
    async fetchResponseSpeed() {
      this.loadingResponseSpeed = true
      const params = {
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
      }
      await this.$http_komship
        .get('/v1/ticket-admin/ranking-performance-mitra/response-speed', { params })
        .then(res => {
          const { data } = res.data
          this.loadingResponseSpeed = false
          this.itemResponseSpeed = data
        })
        .catch(error => {
          this.loadingResponseSpeed = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal mengambil data kecepatan respon',
              icon: 'AlertCircleIcon',
              text: error,
              variant: 'danger',
            },
          }, 2000)
        })
    },
    async fetchHandlingSpeed() {
      this.loadingHandlingSpeed = true
      const params = {
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
      }
      await this.$http_komship
        .get('/v1/ticket-admin/ranking-performance-mitra/handling-speed', { params })
        .then(res => {
          const { data } = res.data
          this.loadingHandlingSpeed = false
          this.itemHandlingSpeed = data
        })
        .catch(error => {
          this.loadingHandlingSpeed = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal mengambil data kecepatan penanganan',
              icon: 'AlertCircleIcon',
              text: error,
              variant: 'danger',
            },
          }, 2000)
        })
    },
    async fetchHandlingSuccess() {
      this.loadingHandlingSuccess = true
      const params = {
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
      }
      await this.$http_komship
        .get('/v1/ticket-admin/ranking-performance-mitra/handling-success', { params })
        .then(res => {
          const { data } = res.data
          this.loadingHandlingSuccess = false
          this.itemHandlingSuccess = data
        })
        .catch(error => {
          this.loadingHandlingSuccess = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal mengambil data kesuksesan penanganan',
              icon: 'AlertCircleIcon',
              text: error,
              variant: 'danger',
            },
          }, 2000)
        })
    },
    async fetchRating() {
      this.loadingRating = true
      const params = {
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
      }
      await this.$http_komship
        .get('/v1/ticket-admin/ranking-performance-mitra/rating', { params })
        .then(res => {
          const { data } = res.data
          this.loadingRating = false
          this.itemRating = data
        })
        .catch(error => {
          this.loadingRating = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal mengambil data review',
              icon: 'AlertCircleIcon',
              text: error,
              variant: 'danger',
            },
          }, 2000)
        })
    },
  },
}
</script>
